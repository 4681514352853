<template>
  <div v-if="dhmeBillOfMaterialStatus === 'success'" class="d-flex flex-column">
    <module-navigation-bar title="Bill of Material">
      <template slot="module-nav-actions">
        <v-tabs
          v-model="bomTab"
          height="46"
          style="width: auto"
          background-color="transparent"
        >
          <v-tab :disabled="sortedGroupsByCode.length === 0">
            {{ $t('modules.BillOfMaterial.container.element') }}
          </v-tab>
          <v-tab :disabled="sortedGroupsByCode.length === 0">
            {{ $t('modules.BillOfMaterial.container.object') }}
          </v-tab>
          <v-tab>
            {{ $t('modules.BillOfMaterial.container.group') }}
          </v-tab>
        </v-tabs>
      </template>
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="d-flex flex-1 flex-scroll-height overflow-hidden">
      <div
        class="background-white ant-border-right radius-0"
        style="width: 300px"
      >
        <div class="supplier-list flex-grow-1 px-2">
          <v-list dense>
            <v-subheader class="pa-0">
              {{ $t('modules.BillOfMaterial.container.groupHeader') }}
              <v-spacer />
              <transition name="simple-fade">
                <v-btn v-if="bomTab === 2" icon @click="groupDialog = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </transition>
            </v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-if="bomTab !== 2"
                class="d-flex align-center pa-2 group-list-item"
                @click="
                  selectedGroup = {
                    group_code: '-1',
                    id: '-1',
                    name: $t('modules.BillOfMaterial.container.unlinked'),
                  }
                "
              >
                <div class="flex-grow-1">
                  {{ $t('modules.BillOfMaterial.container.unlinked') }}
                </div>
              </v-list-item>
              <v-list-item
                v-for="(group, index) in sortedGroupsByCode"
                :key="group.id"
                class="d-flex align-center pa-2 group-list-item"
                @click="selectedGroup = group"
              >
                <div class="flex-grow-1">
                  {{ group?.group_code + ' | ' + group.name }}
                </div>
                <v-btn
                  v-if="bomTab === 2"
                  icon
                  small
                  class="group-delete-btn"
                  @click.stop="setupGroupDelete(group)"
                >
                  <v-icon small> mdi-trash-can </v-icon>
                </v-btn>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-dialog
            key="add-group"
            v-model="groupDialog"
            max-width="500px"
            @click:outside="closeGroupDialogs()"
          >
            <v-card>
              <v-card-title>
                {{
                  $t('modules.BillOfMaterial.container.addGroup')
                }}</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <ant-input label="Code">
                        <template #input-field>
                          <v-text-field
                            v-model="groupItem.group_code"
                            label="Code"
                            dense
                            single-line
                            filled
                            hide-details
                            autofocus
                          />
                        </template>
                      </ant-input>

                      <ant-input label="Name">
                        <template #input-field>
                          <v-text-field
                            v-model="groupItem.name"
                            label="Code"
                            dense
                            single-line
                            filled
                            hide-details
                          />
                        </template>
                      </ant-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="red" text @click="closeGroupDialogs()">
                  Cancel
                </v-btn>
                <v-btn color="primary" elevation="0" @click="createGroup()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <delete-dialog
            :dialog="groupDeleteDialog"
            :title="`Are you sure you want to delete group: ${groupDeleteItem.name}?`"
            @deleteAction="removeGroup"
            @closeDialog="closeGroupDialogs"
          />
        </div>
      </div>
      <div v-if="selectedGroup" class="d-flex flex-1 flex-column">
        <div class="d-flex mb-5 pa-5">
          <div class="d-flex flex-column">
            <div class="font-weight-bold" style="font-size: 18px">
              {{
                $t('modules.BillOfMaterial.elementSchedule.qs', {
                  name: selectedGroup.name,
                })
              }}
            </div>

            <v-menu
              v-model="nameMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template #activator="{ on, attrs }">
                <span class="font-italic" v-bind="attrs" v-on="on">
                  {{ selectedGroup.group_code }} | {{ selectedGroup.name }}

                  <v-icon small class="ml-2">mdi-pencil</v-icon>
                </span>
              </template>
              <v-card>
                <v-text-field
                  v-model="updatedName"
                  dense
                  single-line
                  filled
                  hide-details
                  autofocus
                  @keydown.enter="updateName"
                />
              </v-card>
            </v-menu>
          </div>
          <v-spacer />
          <transition name="simple-fade">
            <v-btn-toggle
              v-if="bomTab === 0 || bomTab === 1"
              v-model="tableTabState"
              color="primary"
              mandatory
              rounded
              class="d-flex align-center"
              background-color="transparent"
            >
              <v-btn value="production" class="ma-0">
                {{ $t('modules.BillOfMaterial.elementSchedule.production') }}
              </v-btn>

              <v-btn value="placement" class="ma-0">
                {{ $t('modules.BillOfMaterial.elementSchedule.placement') }}
              </v-btn>
            </v-btn-toggle>
          </transition>
        </div>

        <div
          class="flex-1 flex-scroll-height overflow-y-auto ant-glass-background ant-border-top radius-0"
        >
          <transition-group name="slide-fade-down" mode="out-in" tag="div">
            <b-o-m-element-schedule
              v-if="bomTab === 0"
              key="bom-elements"
              class="pos-abs"
              :supplier="selectedGroup"
              :table-tab-state="tableTabState"
            />
            <b-o-m-object-schedule
              v-else-if="bomTab === 1"
              key="bom-objects"
              class="pos-abs"
              :supplier="selectedGroup"
              :table-tab-state="tableTabState"
            />
            <b-o-m-group-management
              v-if="bomTab === 2"
              key="bom-groups"
              class="pos-abs"
              :supplier="selectedGroup"
            />
          </transition-group>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_BILL_OF_MATERIAL } from '@/modules/modules';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import AntLoading from '@/components/AntLoading.vue';
import BOMElementSchedule from '@/modules/daiwa-house-modular-europe/BillOfMaterial/DhmeBillOfMaterialElements.vue';
import BOMObjectSchedule from '@/modules/daiwa-house-modular-europe/BillOfMaterial/DhmeBillOfMaterialObjects.vue';
import BOMGroupManagement from '@/modules/daiwa-house-modular-europe/BillOfMaterial/DhmeBillOfMaterialGroups.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeBillOfMaterial',
  components: {
    AntInput,
    PanelResizable,
    BOMGroupManagement,
    BOMObjectSchedule,
    BOMElementSchedule,
    AntLoading,
    DeleteDialog,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      nameMenu: false,
      updatedName: '',
      bomTab: 0,
      groupDialog: false,
      groupItem: {},
      groupDeleteDialog: false,
      groupDeleteItem: {},
      sidebarSize: 300,
      selectedGroup: undefined,
      tableTabState: 'production',
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeBillOfMaterialData',
      'dhmeBillOfMaterialStatus',
      'dhmeBillOfMaterialResourceGroups',
    ]),

    sortedGroupsByCode() {
      return [...this.dhmeBillOfMaterialResourceGroups].sort(function (a, b) {
        return a.group_code - b.group_code;
      });
    },
  },
  watch: {
    selectedGroup(value) {
      this.updatedName = value?.name;
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchBillOfMaterialData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === DHME_BILL_OF_MATERIAL
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => {
        if (this.sortedGroupsByCode.length > 0) {
          this.$store.dispatch('fetchBillOfMaterialTasks');
          this.selectedGroup = this.sortedGroupsByCode[0];
        } else {
          this.bomTab = 2;
        }
      });
  },
  methods: {
    async updateName() {
      await this.$store.dispatch('updateResourceGroupName', {
        recordId: this.selectedGroup.id,
        name: this.updatedName,
      });
      this.nameMenu = false;
    },
    startResizeSidebar(e) {
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResizeSidebar);
    },
    dragSidebar(e) {
      this.sidebarSize = e.clientX + 12;
    },

    stopResizeSidebar(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      document.removeEventListener('mouseup', this.stopResizeSidebar);
    },

    createGroup() {
      this.$store
        .dispatch('createResourceGroup', {
          record: this.groupItem,
        })
        .then(() => {
          this.closeGroupDialogs();
        });
    },
    closeGroupDialogs() {
      this.groupItem = Object.assign({}, {});
      this.groupDeleteItem = Object.assign({}, {});
      this.groupDialog = false;
      this.groupDeleteDialog = false;
    },
    setupGroupDelete(group) {
      this.groupDeleteItem = Object.assign({}, group);
      this.groupDeleteDialog = true;
    },
    async removeGroup() {
      await this.$store.dispatch('removeResourceGroup', this.groupDeleteItem);
      this.closeGroupDialogs();
    },
  },
};
</script>

<style scoped lang="scss">
.group-list-item {
  transition: 200ms ease-out;

  .group-delete-btn {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .group-delete-btn {
      opacity: 1;
    }
  }
}
</style>
